<template>
  <div class="white">
      <div class="fullscreen-bg vid-back" v-if="$vuetify.breakpoint.mdAndUp">
        <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
          <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Fmontage23s.mp4?alt=media&token=73bcf38f-6bb7-479a-91b4-0559cbe550a0" type="video/mp4">
        </video>
        <div class="overlay-clear pad-fix vid-fix">
          <div>
            <div class="display-3">
              <span class="thin">Technology that</span>
            </div><br />
            <div class="display-4">
              <span class="thick">Transforms</span>
            </div>
            <div>
              <span class="italic display-1 pl-4">"Everything"</span>
            </div>
            <v-btn small outline dark :to="{ name: 'technology' }" class="ml-0 mt-2">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
          </div>
        </div>
      </div>

      <div class="fullscreen-bg vid-back" v-if="$vuetify.breakpoint.smAndDown">
        <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
          <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Fmontage24Small.mp4?alt=media&token=d6f1df52-6eae-435c-9055-442dd25a4099" type="video/mp4">
        </video>
      <div class="overlay-clear pad-fix vid-fix">
        <div>
          <div class="display-3">
            <span class="thin">Technology that</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Transforms</span>
          </div>
          <div>
            <span class="italic display-1 pl-4">"Everything"</span>
          </div>
          <v-btn small outline dark :to="{ name: 'technology' }" class="ml-0 mt-2">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
        </div>
      </div>
    </div>

    <section v-if="$vuetify.breakpoint.mdAndUp">
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Fwristbandtwist.mp4?alt=media&token=ee3ef1f4-2fed-4df3-b219-f091a675fca6">
      </video>
      <div class="overlay-clear pad-fix">
        <div>
          <br />
          <div class="display-4 mb-2">
            <span class="thick">Wearables</span>
          </div>
          <v-btn small outline dark :to="{ name: 'wearables' }" class="ml-0 mt-0">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
     
        </div>
      </div>
    </div>
     </section>

     <section v-if="$vuetify.breakpoint.smAndDown">
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Fwristbandtwistsmall.mp4?alt=media&token=1c782fac-e874-42cc-9423-4d4ffa35246d">
      </video>
      <div class="overlay-clear pad-fix">
        <div>
          <br />
          <div class="display-4 mb-2">
            <span class="thick">Wearables</span>
          </div>
          <v-btn small outline dark :to="{ name: 'wearables' }" class="ml-0 mt-0">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
     
        </div>
      </div>
    </div>
     </section>

     <section>
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/videos%2Fdata.mp4?alt=media&token=ea3b7a9a-25bd-4eb1-9779-43a28ab67172">
      </video>
      <div class="overlay-clear pad-fix">
        <div>
          <br />
          <div class="display-4 mb-2">
            <span class="thick">Data, Data, and More Data</span>
          </div>
          <v-btn small outline dark :to="{ name: 'data' }" class="ml-0 mt-0">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
     
        </div>
      </div>
    </div>
     </section>

     <section>
      <v-img src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/sstblank.png?alt=media&token=36375f60-4b6c-476d-8026-4228ea5f23be"
      >
      <div class="overlay-clear pad-fix">
        <div>
          <div class="display-3">
            <span class="thin">Smart</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Shelf</span>
          </div>
          
          <v-btn small outline dark :to="{ name: 'logistics' }" class="ml-0 mt-2">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
     
        </div>
     
      </div>
      </v-img>
    </section>
    

    <section>
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2FVegasLifesmall.mp4?alt=media&token=31d213e8-530a-4ebb-a061-b0a861787508">
      </video>
      <div class="overlay pad-fix">
        <div>
          <br />
          <div class="display-4 mb-2">
            <span class="thick">Drinkware</span>
          </div>
          <v-btn small outline dark :to="{ name: 'consumer-goods' }" class="ml-0 mt-0">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
     
        </div>
      </div>
    </div>
     </section>
     

    <section>
      <v-img src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/wall2.jpg?alt=media&token=28db1030-f91d-4539-bbbc-31b5e49890d2"
      >
      <div class="overlay pad-fix">
        <div>
          <div class="display-3">
            <span class="thin">22 Patents and</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Growing</span>
          </div>
          
          <v-btn small outline dark :to="{ name: 'patents' }" class="ml-0 mt-2">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
     
        </div>
     
      </div>
      </v-img>
    </section>
    <section class="hidden-md-and-up" style="display:none;">
      <v-img src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/viizzii-birthday.jpg?alt=media&token=462913ef-ee3c-4c8e-af12-738072a64b12"
      :gradient="gradient"
      >
      <div class="overlay-clear pad-fix">
        <div>
          <div class="display-3">
            <span class="thin">Make the Ordinary</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Extraordinary!</span>
          </div>
          
          <v-btn small outline dark :to="{ name: 'consumer-goods' }" class="ml-0 mt-2">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
        </div>
      </div>
      </v-img>
    </section>




    <section>
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/videos%2Fviizziitransitions.mp4?alt=media&token=4d57218a-9429-401b-ae53-7f32942e635c">
        
      </video>
      <div class="overlay pad-fix">
        <div>
          <div class="display-3">
            <span class="thin">Make the Ordinary</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Extraordinary!</span>
          </div>
          
          <v-btn small outline dark :to="{ name: 'consumer-goods' }" class="ml-0 mt-2">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
        </div>
      </div>
    </div>
     </section>

     <section>
      <v-img src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/spt1blank.png?alt=media&token=ed435dbb-9ccc-47f1-89b6-c3235814d6b4"
      >
      <div class="overlay-clear pad-fix">
        <div>
          <div class="display-3">
            <span class="thin">Smart</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Pallet</span>
          </div>
          
          <v-btn small outline dark :to="{ name: 'logistics' }" class="ml-0 mt-2">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
     
        </div>
     
      </div>
      </v-img>
    </section>

    <section>
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/videos%2FMED01_Montage03_02no_congrats_with4th02_WebM_1080p.mp4?alt=media&token=62d5b2f8-9584-458b-988d-a6c7387ad0f7" type="video/mp4">
        
      </video>
      <div class="overlay-clear pad-fix">
      <div>
        <br />

          <div class="display-4">
            <span class="thick">MEDEA Vodka</span>
          </div>

        <div>
          Incredible Technology & Taste!
        </div>
        <v-btn small outline dark class="ml-0 mt-2" :to="{ name: 'medea' }">Open Case Study<v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
        </div>
      </div>
    </div>
     </section>


     <section>
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/videos%2Ftaylor.mp4?alt=media&token=1afd33be-a1f9-4d65-908e-3714d7abb679">
        
      </video>
      <div class="overlay-clear pad-fix">
        <div>
          <div class="display-3">
            <span class="thin">Amazing Arena</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Takeovers</span>
          </div>
          <v-btn small outline dark :to="{ name: 'fan' }" class="ml-0 mt-2">Learn More <v-icon right dark>keyboard_arrow_right</v-icon></v-btn>
        </div>
      </div>
    </div>
     </section>


    

    

    <div class="relative">
    <div class="hotdog text-xs-center"><span class="display-1"><span class="thick">Other Channels</span></span></div>
    <v-container fluid class="pa-0">
      <v-layout row wrap>
        <v-flex
          xs6
          sm4
          lg4
          
          class="leftpic"
        >
          <router-link :to="{ name: 'fan' }" class="nounder">
            <v-card flat class="sol-card">
              <v-img
                class="white--text"
                
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/ATT_Park_San_Francisco_baseball_stadium-1920x1080.jpg?alt=media&token=641f2e0d-01fd-4010-b217-a6e0ea6427c7"
                lazy-src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/ATT_Park_San_Francisco_baseball_stadium-1920x1080.jpg?alt=media&token=641f2e0d-01fd-4010-b217-a6e0ea6427c7"
              >
              <div class="overlay pad-fix">
                <div>
                    <div class="display-1">
                      <span class="thick">Fan Experiences</span>
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex
          xs6
          sm4
          lg4
          class="rightpic"
        >
          <router-link :to="{ name: 'consumer-goods' }" class="nounder">
            <v-card flat class="sol-card">
              <v-img
                class="white--text"
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/viizzii-birthday.jpg?alt=media&token=462913ef-ee3c-4c8e-af12-738072a64b12"
                lazy-src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/viizzii-birthday.jpg?alt=media&token=462913ef-ee3c-4c8e-af12-738072a64b12"
              >
              <div class="overlay pad-fix">
                <div>
                    <div class="display-1">
                      <span class="thick">Consumer Goods</span>
                    </div>
                  </div>
                </div>
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex
          xs6
          sm4
          lg4
          
          class="leftpic"
        >
          <router-link :to="{ name: 'conventions' }" class="nounder">
            <v-card flat class="sol-card">
              <v-img
                class="white--text"
                
                
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/convention.jpg?alt=media&token=348e4d6d-6f1c-447f-ae86-2a83ac7c6683"
                lazy-src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/convention.jpg?alt=media&token=348e4d6d-6f1c-447f-ae86-2a83ac7c6683"
              >
              <div class="overlay pad-fix">
                <div>
                    <div class="display-1">
                      <span class="thick">Conventions</span>
                    </div>
                  </div>
                </div>
              
              
              </v-img>
            </v-card>
          </router-link>
        </v-flex>



        <v-flex
          xs6
          sm4
          lg4
          
          class="rightpic"
        >
          <router-link :to="{ name: 'advertising' }" class="nounder">
            <v-card flat class="sol-card">
              <v-img
                class="white--text"
                
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/truck.jpg?alt=media&token=04598668-02ac-42a4-9998-c2b0481da596"
                lazy-src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/truck.jpg?alt=media&token=04598668-02ac-42a4-9998-c2b0481da596"
              >

              <div class="overlay pad-fix">
                <div>
                    <div class="display-1">
                      <span class="thick">Advertising</span>
                    </div>
                  </div>
                </div>
              
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex
          xs6
          sm4
          lg4
          
          class="leftpic"
        >
          <router-link :to="{ name: 'retail' }" class="nounder">
            <v-card flat class="sol-card">
              <v-img
                class="white--text"
                
                
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/shopping.jpg?alt=media&token=5db83601-892a-4c45-8ae6-c4a100454e8a"
                lazy-src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/shopping.jpg?alt=media&token=5db83601-892a-4c45-8ae6-c4a100454e8a"
              >
              <div class="overlay pad-fix">
                <div>
                    <div class="display-1">
                      <span class="thick">Retail</span>
                    </div>
                  </div>
                </div>
             
              
              </v-img>
            </v-card>
          </router-link>
        </v-flex>

        <v-flex
          xs6
          sm4
          lg4
          
          class="rightpic"
        >
          <router-link :to="{ name: 'healthcare' }" class="nounder">
            <v-card flat class="sol-card">
              <v-img
                class="white--text"
                
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/doc.jpg?alt=media&token=92042c92-8cea-4681-885a-9edc96018e71"
                lazy-src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/doc.jpg?alt=media&token=92042c92-8cea-4681-885a-9edc96018e71"
              >
              <div class="overlay pad-fix">
                <div>
                    <div class="display-1">
                      <span class="thick">Healthcare</span>
                    </div>
                  </div>
                </div>
              
             </v-img>
            </v-card>
          </router-link>
        </v-flex>
      </v-layout>
    </v-container>
    </div>
    <div class="white relative" style="display:none;">
    <div class="hotdog text-xs-center"><span class="display-1"><span class="thick">Our Valued Partners</span></span></div>
      <div grid-list-md class="pt-3 pb-2" style="display:none;">
      <div class="ma-5">
        <v-layout row wrap>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/fanaticssq.png?alt=media&token=43670d03-6bc0-48e5-91e3-b652a8eb660e"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/kingssq.png?alt=media&token=3b3cdb34-5015-4700-b16d-b05e92c42330"
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/krogersq.png?alt=media&token=85491115-9cfd-4a07-af8a-288120425010"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/ftsq.png?alt=media&token=1a9b358e-4621-461f-a4ac-1cb8c3f7a9f5"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/syndicate-sales-logo%20copy.jpg?alt=media&token=d8f0d05f-0420-4599-90e4-8acc254c0fb0"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/fanaticssq.png?alt=media&token=43670d03-6bc0-48e5-91e3-b652a8eb660e"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/kingssq.png?alt=media&token=3b3cdb34-5015-4700-b16d-b05e92c42330"
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/krogersq.png?alt=media&token=85491115-9cfd-4a07-af8a-288120425010"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/ftsq.png?alt=media&token=1a9b358e-4621-461f-a4ac-1cb8c3f7a9f5"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/syndicate-sales-logo%20copy.jpg?alt=media&token=d8f0d05f-0420-4599-90e4-8acc254c0fb0"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/fanaticssq.png?alt=media&token=43670d03-6bc0-48e5-91e3-b652a8eb660e"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/kingssq.png?alt=media&token=3b3cdb34-5015-4700-b16d-b05e92c42330"
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/krogersq.png?alt=media&token=85491115-9cfd-4a07-af8a-288120425010"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/ftsq.png?alt=media&token=1a9b358e-4621-461f-a4ac-1cb8c3f7a9f5"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/syndicate-sales-logo%20copy.jpg?alt=media&token=d8f0d05f-0420-4599-90e4-8acc254c0fb0"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/fanaticssq.png?alt=media&token=43670d03-6bc0-48e5-91e3-b652a8eb660e"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/kingssq.png?alt=media&token=3b3cdb34-5015-4700-b16d-b05e92c42330"
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/krogersq.png?alt=media&token=85491115-9cfd-4a07-af8a-288120425010"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/ftsq.png?alt=media&token=1a9b358e-4621-461f-a4ac-1cb8c3f7a9f5"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          <v-flex xs2 sm1 d-flex class="pa-2">
            <v-card flat tile class="d-flex">
              <v-img
                src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/syndicate-sales-logo%20copy.jpg?alt=media&token=d8f0d05f-0420-4599-90e4-8acc254c0fb0"
                
                aspect-ratio="1"
                >
              </v-img>
            </v-card>
          </v-flex>
          
        </v-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {
  data: () => ({
    gradient: "to top right, rgba(0,0,0, 0.2), rgba(0, 0, 0, 0.2)"
  }),
}
</script>
