import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=2ed5e182&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/Users/gregfielding/techcentrix/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
installComponents(component, {VContainer,VDivider,VFlex,VIcon,VLayout,VListTile,VListTileContent,VListTileTitle})
