import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/technology',
      name: 'technology',
      component: () => import(/* webpackChunkName: "technology" */ './views/Technology.vue')
    },
    {
      path: '/technology/data',
      name: 'data',
      component: () => import(/* webpackChunkName: "data" */ './views/Data.vue')
    },
    {
      path: '/technology/patents',
      name: 'patents',
      component: () => import(/* webpackChunkName: "patents" */ './views/Patents.vue')
    },
    {
      path: '/industries',
      name: 'industries',
      component: () => import(/* webpackChunkName: "solutions" */ './views/Solutions.vue')
    },
    {
      path: '/industries/fan-engagement',
      name: 'fan',
      component: () => import(/* webpackChunkName: "fan" */ './views/Fan.vue')
    },
    {
      path: '/industries/consumer-goods',
      name: 'consumer-goods',
      component: () => import(/* webpackChunkName: "consumer-goods" */ './views/ConsumerGoods.vue')
    },
    {
      path: '/industries/beverage-licensing',
      name: 'beverage-licensing',
      component: () => import(/* webpackChunkName: "beverage-licensing" */ './views/BeverageLicensing.vue')
    },
    {
      path: '/industries/logistics',
      name: 'logistics',
      component: () => import(/* webpackChunkName: "logistics" */ './views/Logistics.vue')
    },
    {
      path: '/industries/conventions',
      name: 'conventions',
      component: () => import(/* webpackChunkName: "conventions" */ './views/Conventions.vue')
    },
    {
      path: '/industries/advertising',
      name: 'advertising',
      component: () => import(/* webpackChunkName: "advertising" */ './views/Advertising.vue')
    },
    {
      path: '/industries/retail',
      name: 'retail',
      component: () => import(/* webpackChunkName: "retail" */ './views/Retail.vue')
    },
    {
      path: '/industries/wearables',
      name: 'wearables',
      component: () => import(/* webpackChunkName: "wearables" */ './views/Wearables.vue')
    },
    {
      path: '/industries/healthcare',
      name: 'healthcare',
      component: () => import(/* webpackChunkName: "healthcare" */ './views/Healthcare.vue')
    },
    {
      path: '/licensing',
      name: 'licensing',
      component: () => import(/* webpackChunkName: "licensing" */ './views/Licensing.vue')
    },
    {
      path: '/ambassadors',
      name: 'ambassadors',
      component: () => import(/* webpackChunkName: "ambassadors" */ './views/Ambassadors.vue')
    },
    {
      path: '/connect',
      name: 'connect',
      component: () => import(/* webpackChunkName: "connect" */ './views/Connect.vue')
    },
    {
      path: '/case-studies/medea',
      name: 'medea',
      component: () => import(/* webpackChunkName: "medea" */ './views/Medea.vue')
    },
    {
      path: '/case-studies/syndicate-sales',
      name: 'syndicate-sales',
      component: () => import(/* webpackChunkName: "syndicate-sales" */ './views/SyndicateSales.vue')
    }
  ]
})

export default router
