<template>
	<div>
		<v-toolbar flat light prominent>
			<v-toolbar-title>
  			<router-link :to="{ name: 'home' }" color="white">
  				<span class="thick">Tech</span><span class="thin">Centrix</span>
          <!-- 
  				<span class="mr-1 ml-1 mt-0 display-1 thinner">|</span>
        	<span class="reg italic">"Transforming IOT"</span>
           -->

        
        </router-link>

      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-side-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-toolbar-side-icon>

      <v-toolbar-items class="hidden-sm-and-down">        
        <v-btn :ripple="false" flat color="text-light" :to="{ name: 'technology' }">Our Technology</v-btn>
        <v-btn :ripple="false" flat color="text-light" :to="{ name: 'industries' }">Industries</v-btn>
        <v-btn :ripple="false" flat color="text-light" :to="{ name: 'connect' }">Connect</v-btn>
      </v-toolbar-items>
  	</v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      dark
    >
      <v-list
        class="py-5 mt-4 ma-3"
      > 
        <div class="mt-1">
          <router-link to="/" class="nounder white--text">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>
        <v-divider class="my-2"></v-divider>
        <div>
          <router-link to="/technology" class="nounder white--text">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Our Technology</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>
        <v-divider class="my-2"></v-divider>
        <div>
          <router-link to="/industries" class="nounder white--text">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Industries</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>
        <v-divider class="my-2"></v-divider>
        <div>
          <router-link to="/connect" class="nounder white--text">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Connect</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>
      </v-list>
    </v-navigation-drawer>
	</div>
</template>

<script>
	export default {
    data: () => ({
      color: 'black',
      drawer: false,
  	})
  }
</script>